import React from "react";

interface CityCoinsLogoProps {
  width: string;
  height: string;
}

const CityCoinsLogo: React.FC<CityCoinsLogoProps> = ({ width, height }) => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 218 218"
      width={width}
      height={height}
    >
      <circle cx="109" cy="109" r="105" fill="#3059D9" />
      <path
        fill="white"
        d="M103.6,139.15L103.6,139.15l3.01,4.39l-24.21-0.03c-9.22-0.01-17.9-3.62-24.43-10.17
			c-6.53-6.55-10.12-15.24-10.1-24.46c0.03-19.04,15.54-34.53,34.58-34.53l24.26,0.03l-3.02,4.39c-6.15,8.91-9.4,19.35-9.42,30.18
			C94.25,119.77,97.47,130.21,103.6,139.15 M188.98,74.49L189,55.62L82.52,55.48c-29.5,0-53.48,23.95-53.52,53.38
			c-0.02,14.25,5.52,27.67,15.62,37.79c10.09,10.12,23.5,15.71,37.75,15.73l106.48,0.14l0.02-18.86l-41.21-0.06
			c-9.22-0.01-17.9-3.63-24.43-10.17c-6.53-6.55-10.12-15.24-10.11-24.46c0.01-9.22,3.62-17.9,10.17-24.43
			c6.54-6.52,15.21-10.11,24.41-10.11L188.98,74.49z"
      />
    </svg>
  );
};

export default CityCoinsLogo;
